export const infos = {
  main_title: `Chapitre bonus`,
  title: `La route de pavés&nbsp;jaunes`,
  desc: `Clic-clic. Pour quitter Malakoff, inutile de chausser vos souliers d’argent. Parcourez la route de pavés jaunes en musique et explorez les différentes régions du Pays d’Oz. L’application Balades sonores vous fera découvrir autrement cette ville que vous croyez connaître.`,
};

export const parcours = {
  infos: {
    title: `La route<br>de pavés jaunes`,
    desc: `Description`,
    color: "#9FC900",
  },
  route: [
    [0],
    [48.822576409251795, 2.3060129836622094],
    [48.82146500967951, 2.304369080121757],
    [48.82093227684872, 2.30262474587148],
    [48.82137506817031, 2.3014793697673825],
    [48.821056812303304, 2.3004600901151124],
    [48.8205586686284, 2.3008593955458987],
    [48.82052407513381, 2.30137428939086],
    [48.82069704236804, 2.30193121538643],

    [1],
    [48.81979068742988, 2.301584450143905],
    [48.81977684982289, 2.301311241164946],
    [48.8193063689122, 2.3010065080730304],
    [48.81924645120882, 2.2978325009798097],
    [48.818139037042094, 2.296397968693931],

    [2],
    [48.81634361088003, 2.292942836402962],
    [48.8166012984067, 2.292477525781921],
    [48.81450312405091, 2.2878885356075465],
    [48.81428843486508, 2.287897115195538],
    [48.814275471331, 2.2873970041593705],
    [48.81274540133481, 2.2860863963773954],
    [3],
  ],
  balises: [
    {
      chapter: "I",
      name: "Banquet d’automne",
      subname: "AUTOMNE",
      text: `«&nbsp;Ding-dong, la sorcière est morte&nbsp;!&nbsp;» Il fait doux pour la saison. Le chant des Munchkins flotte dans l’air. Ici, on apporte des tartes au potiron et d’autres aux pommes, là, on dresse les nappes bleues sur les tables. L’odeur des pains de maïs chauds se fait de plus en plus forte. Le banquet va pouvoir commencer. Prenez des forces avant de vous mettre en route. Ou restez si vous pensez avoir fini.`,
      coords: [48.822576409251795, 2.3060129836622094],
      audio_file: `1-automne.aac`,
    },
    {
      chapter: "II",
      name: "Filer comme le vent",
      subname: "HIVER",
      text: `Le vent souffle au milieu des pics inhospitaliers. Un corbeau croasse. Un enfant Gillikin enfonce son bonnet violet jusqu’aux yeux et s’engonce dans son manteau zinzolin. La silhouette des arbres décharnés se transforme en ombre. C’est le crépuscule.`,
      coords: [48.820744224894334, 2.301160342327248],
      audio_file: `2-hiver.aac`,
    },
    {
      chapter: "III",
      name: "Renaissance de la nature",
      subname: "PRINTEMPS",
      text: `Après la pluie vient le beau temps. Après l’hiver, la nature renaît. Les lapins sortent le nez de leur terrier. Dans leurs robes jaunes, des femmes avancent, leurs paniers remplis d’œufs. Les Winkies préparent une grande fête pour l’arrivée du printemps. Vous resterez bien un peu pour vous reposer avant la prochaine étape&nbsp;?`,
      coords: [48.81792742488937, 2.2958673105413667],
      audio_file: `3-printemps.aac`,
    },
    {
      chapter: "IV",
      name: "Chaud été",
      subname: "ÉTÉ",
      text: `Ocre est la poussière que soulève le vent. Assoiffé, le voyageur cherche du regard un puits ou une auberge. Les maisons de briques créent une toile rouge uniforme sur laquelle tout semble immobile. Il faut plisser les yeux pour distinguer des Quadlings, de rouge vêtus, se déplacent. Le voyageur s’approche et un large sourire s’épanouit sur sa face burinée quand on lui apporte un saladier rempli de cerises juteuses. C’est la fin du voyage. A moins que ce n’en soit le début.`,
      coords: [48.81274540133481, 2.2860863963773954],
      audio_file: `4-ete.aac`,
    },
  ],
};
